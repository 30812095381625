// Dependencies.
import Profil1 from '../assets/images/profil_1.png';
import Profil2 from '../assets/images/profil_2.png';

// import ChatMessagesHeader from './msgheader.jsx';
import ChatMessageEditor from './msgeditor.jsx';
import DateTime from '../libs/datetime.js';
import ChatContext from './chat.jsx';
import lodash from 'lodash';
import React, { useState, useRef } from 'react';
import '../css/body.css';
import OpenAI from 'openai';

/*
 * @description: Creates application body view.
 * @type: UI
 */
const Body = () => {
  const chat_context = useRef();
  const openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true,
  });
  // const [0, setActiveIndex] = useState(0);
  const [chats, setChats] = useState([
    {
      label: "This message isn't...",
      name: 'Neopolis GPT',
      date: '9/3/2022',
      profil: Profil2,
      data: [
        {
          datetime: DateTime.get_datetime(),
          messages: [
            {
              is_contact: true,
              text: '👨‍💼 Je suis Jacques, un expert en assurance, je suis là pour répondre à toutes vos questions et je peux vous aider à trouver les meilleurs choix.',
            },
          ],
        },
      ],
    },
  ]);
  /*React.useEffect(() => {
  console.log(chats);
}, [chats]);*/
  /*
   * @description: Sends a written message to another connected user.
   * @parameters:
   *   -> String datetime: What the current datetime for message to send ?
   *   -> bool is_contact: Is it from the main user or his contact ?
   *   -> String message: Contains the message to be sent.
   * @return: void
   */
  const __send_message = async (datetime, is_contact, message) => {
    // Gets chats data.
    let chatsCopy = lodash.cloneDeep(chats),
      today = datetime.split(',')[0] + ', ' + new Date().getFullYear();
    // Gets chat datetime index.
    let index = chatsCopy[0].data.findLastIndex((item) => {
      // Returns the imposed constraint.
      return (
        item.datetime.split(',')[0] + ', ' + new Date().getFullYear() === today
      );
    });
    // The given datetime is it defined ?
    if (index > -1)
      chatsCopy[0].data[index].messages.push({
        is_contact: is_contact,
        text: message,
      });
    // Adds the current message with the given datetime for today.
    else
      chatsCopy[0].data.push({
        datetime: DateTime.get_datetime(),
        messages: [{ is_contact: is_contact, text: message }],
      });
    // Updates the global state.
    setChats(chatsCopy);
    // Moves the scrollbar at the full bottom.
    setTimeout(() => chat_context.current.scroll_to_bottom(), 100);
    const completion = await openai.chat.completions.create({
      stream: true,
      messages: [
        {
          role: 'system',
          content:
            "Vous vous appelez Jacques et vous êtes un chatbot expert assurance, votre rôle est de répondre exclusivement sur les questions relatives aux assurances en général et recommander et expliquer les produits et services proposés par le site web Assurtech. Vos objectifs sont : Collecter les coordonnées des visiteurs du site (nom, tel,email ....). Demander des informations sur leurs  activités et leur montrer l'intérêt de souscrire des assurances selon des cas proches de leurs activités professionnelles. Vous utilisez des paragraphes courts et pas plus de 2 ou 3 paragraphes pour évitez les réponses longues et tardives. N'oubliez pas de ne pas citer les concurrents et de montrer l'intérêt des contrats d'assurances que nous proposons. etudiez les page du siteweb dev.assurtech.fr et utilisez les principalement avec le code des assurance francais pour  (ici https://www.legifrance.gouv.fr/codes/texte_lc/LEGITEXT000006073984/ )pour vos reponses ",
        },
        { role: 'user', content: message },
      ],
      model: 'gpt-3.5-turbo',
    });
    // debugger;
    let answare = '';
    for await (const chunk of completion) {
      answare += chunk.choices[0].delta.content
        ? chunk.choices[0].delta.content
        : '';
      // console.log(chunk.choices[0].delta.content);

      let chatsCopy2 = lodash.cloneDeep(chatsCopy);
      if (index > -1) {
        chatsCopy2[0].data[index].messages.push({
          is_contact: true,
          text: answare,
        });
      }
      // Adds the current message with the given datetime for today.
      else {
        chatsCopy2[0].data.push({
          datetime: DateTime.get_datetime(),
          messages: [{ is_contact: true, text: answare }],
        });
      }
      setChats(chatsCopy2);
    }

    // Moves the scrollbar at the full bottom.
    setTimeout(() => chat_context.current.scroll_to_bottom(), 100);
    // Send message to API
  };

  return (
    <div className='chat-workspace'>
      {/* Global container */}
      <div className='chat-container'>
        {/* Availables users contacts */}

        {/* Messages worksapce */}
        <div className='messages-workspace'>
          {/* Messages header container */}
          {/* <ChatMessagesHeader contact={chats[0]} /> */}
          {/* Chat context */}
          <ChatContext
            chats={chats[0].data}
            userProfil={Profil1}
            contactProfil={chats[0].profil}
            ref={chat_context}
          />
          {/* Message editor */}
          <ChatMessageEditor sendMessage={__send_message} />
        </div>
      </div>
    </div>
  );
};

export default Body;
